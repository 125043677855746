import {useRef} from 'react'
import {PropertyCardProps} from 'src/types/property'
import {PageProps} from 'src/types/page'
import PropertyCard from 'src/components/modules/property/card/PropertyCard'
import PagePagination from './PagePagination'

function PageProperties(props: {page: PageProps}) {
  const {page} = props
  const gridRef = useRef<HTMLDivElement | null>(null)

  if (!page.properties.length) {
    return null
  }

  return (
    <div ref={gridRef}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {page.properties.map((property: PropertyCardProps) => {
          return <PropertyCard key={property.id} data={property} />
        })}
      </div>
      <PagePagination page={page} />
    </div>
  )
}

export default PageProperties
