import {makeStyles} from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'
import {PaginationProps} from '@material-ui/lab/Pagination/Pagination'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({palette, spacing}) => ({
  paginationWrapper: {
    '& ul': {
      margin: spacing(4, 0),
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      gap: spacing(1),
    },
    '& li': {
      height: spacing(5),
      width: spacing(5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:first-child': {
        border: `1px solid ${palette.grey[200]}`,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '&:last-child': {
        border: `1px solid ${palette.grey[200]}`,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    '& .MuiPaginationItem-root': {
      minWidth: spacing(5),
      height: spacing(5),
      borderRadius: spacing(2.5),
    },
  },
}))

export default function AppPagination(props: {
  count: number
  page: number
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<unknown>, value: number) => void
  otherProps?: PaginationProps
}) {
  const {count, page, disabled, onChange, otherProps = {}} = props
  const classes = useStyles()

  return (
    <Pagination
      disabled={disabled}
      count={count}
      page={page}
      color="primary"
      onChange={onChange}
      className={classes.paginationWrapper}
      {...otherProps}
    />
  )
}
