import {useRouter} from 'next/router'
import {PaginationItem} from '@material-ui/lab'
import {PaginationRenderItemParams} from '@material-ui/lab/Pagination/Pagination'
import AppPagination from 'src/components/elements/AppPagination'
import AppLink from 'src/components/elements/Link'
import {PageProps} from 'src/types/page'

function PagePagination(props: {page: PageProps}) {
  const {page} = props
  const router = useRouter()
  if (page.properties_pagination_data.total_pages <= 1) {
    return null
  }

  const paginationLink = (page: number) => {
    let slug = router.asPath.split('/')
    let pageIdx = slug.indexOf('page')
    if (pageIdx !== -1) {
      slug.splice(pageIdx, slug.length - pageIdx) //remove page and page number from slug
    }
    let finalUrl = slug.join('/')

    if (page <= 1) return finalUrl //dont include page in url if page is 1
    return `${finalUrl}/page/${page}`
  }

  return (
    <AppPagination
      count={page.properties_pagination_data.total_pages}
      page={page.page_number}
      otherProps={{
        renderItem: (params: PaginationRenderItemParams) => {
          //use href link so we got good seo
          return (
            <AppLink href={paginationLink(params.page)}>
              <PaginationItem {...params} />
            </AppLink>
          )
        },
      }}
    />
  )
}

export default PagePagination
