import {PageProps} from 'src/types/page'
import useStyles from 'src/components/modules/page/styles'
import PageFooterHtml from 'src/components/modules/page/PageFooterHtml'
import PageProperties from 'src/components/modules/page/PageProperties'
import PageBaseTemplate from 'src/components/modules/page/PageBaseTemplate'
import Container from '@material-ui/core/Container'

function PageDefaultTemplate(props: {page: PageProps}) {
  const {page} = props

  const classes = useStyles({})

  return (
    <PageBaseTemplate page={page}>
      <Container disableGutters className={classes.container} maxWidth="lg">
        {page.first_content_html && (
          <div
            className={`editor_content -first`}
            dangerouslySetInnerHTML={{__html: page.first_content_html}}
          />
        )}
        <PageProperties page={page} />
        {page.last_content_html && (
          <div
            className={`editor_content -last`}
            dangerouslySetInnerHTML={{__html: page.last_content_html}}
          />
        )}
        <PageFooterHtml page={page} />
      </Container>
    </PageBaseTemplate>
  )
}

export default PageDefaultTemplate
